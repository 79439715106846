import "./src/styles/global.css";
import React from "react";

import CookieConsent from "./src/components/cookieconsent/CookieConsent";

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      {element}
      <CookieConsent />
    </>
  );
};
