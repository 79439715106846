import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import {
  ChakraProvider,
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";

const CookieConsent = () => {
  const location = useLocation();
  const shouldBeVisible = location.pathname === "/case-study/";

  const [isVisible, setIsVisible] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    // Check if the cookie consent has already been accepted
    const consent = localStorage.getItem("cookieConsent");
    if (consent !== "accepted") {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    // Save the user's consent to localStorage
    localStorage.setItem("cookieConsent", "accepted");
    setIsVisible(false);
    trackButtonClick();
  };

  const trackButtonClick = () => {
    if (typeof window !== "undefined" && window.dataLayer) {
      window.dataLayer.push({
        event: "cookie_consent_agreed",
        event_category: "Button",
        event_label: "Cookie Consent Agreed",
      });
    }
  };

  const schema = yup.object().shape({
    industry: yup.string().required("This field is required"),
    business_email: yup.string().email(),
  });
  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      industry: "",
      business_email: "",
    },
  });

  const handleOnSubmit = (formDetails) => {
    // e.preventDefault();
    // Handle form submission, like sending data to a server

    onClose();
    console.log("formDetails: ", formDetails);
    axios
      .post(
        "https://qk2pkf192j.execute-api.us-east-2.amazonaws.com/prod/cookieForm",
        formDetails
      )
      .then(() => {
        console.log("Successfully submitted");
        trackButtonClick(formDetails);

        handleAccept();
      })
      .catch((error) => {
        console.log(error);
        handleAccept();
      });
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      {!shouldBeVisible ? (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            width: "100%",
            backgroundColor: "white",
            textAlign: "center",
            padding: "20px",
            boxShadow: "0px -2px 5px rgba(0, 0, 0, 0.2)",
            zIndex: 1000,
          }}
        >
          <p>
            We use cookies for a number of reasons, such as keeping the vsNEW
            site reliable and secure, providing social media features, and
            analyzing how our site is used.
          </p>
          <button
            onClick={handleAccept}
            style={{
              backgroundColor: "#4CAF50",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Accept and Continue
          </button>
        </div>
      ) : (
        <>
          <ChakraProvider>
            <Modal isOpen={true}>
              <ModalOverlay />
              <ModalContent>
                <form onSubmit={handleSubmit(handleOnSubmit)}>
                  <ModalHeader></ModalHeader>
                  {/* <ModalCloseButton /> */}
                  <ModalBody>
                    <h5>Do you work in the auto industry? (Required)</h5>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label>
                        <input
                          type="radio"
                          name="industry"
                          value="dealership"
                          {...register("industry")}
                        />{" "}
                        I work at a dealership
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="industry"
                          value="auction"
                          {...register("industry")}
                        />{" "}
                        I work at an auction
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="industry"
                          value="automotive_company"
                          {...register("industry")}
                        />{" "}
                        I work at another automotive company
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="industry"
                          value="consumer"
                          {...register("industry")}
                        />{" "}
                        I am a consumer
                      </label>

                      <br />
                      <label for="email">
                        Business Email Address (optional)
                      </label>
                      <input
                        type="email"
                        id="business_email"
                        name="business_email"
                        placeholder="Business Email"
                        {...register("business_email")}
                      />
                    </div>

                    <br />
                    <p>
                      vsNEW uses cookies for a number of reasons, such as
                      keeping the vsNEW site reliable and secure, providing
                      social media features, and analyzing how our site is used.
                    </p>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      type="submit"
                      variant="primary"
                      style={{
                        backgroundColor: "#4CAF50",
                        color: "white",
                        padding: "10px 20px",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      Accept and Continue
                    </Button>
                  </ModalFooter>
                </form>
              </ModalContent>
            </Modal>
          </ChakraProvider>
        </>
      )}
    </>
  );
};

export default CookieConsent;
